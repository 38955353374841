import React from "react"
import {graphql, StaticQuery} from "gatsby";
import Slides from "./slides";


const ContentSlider = ({ exitTransition, entryTransition }) => {

    return (
        <StaticQuery
        query={graphql`
            query {
              article: allStrapiArticle(filter: {active: {eq: true}, highlighted_articles: {elemMatch: {name: {eq: "Front page articles"}}}}, sort: {order: DESC, fields: published_at}) {
                edges {
                    node {
                        title
                        slug
                        published_at
                        content
                        blog_category {
                            name
                            slug
                            icon {
                                publicURL
                            }
                        }
                        image {
                            childImageSharp {
                                fluid {
                                     ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        } 
                    }
                }
              }
            }
        `}

            render={
                data => (
                    <Slides articles={data.article.edges} exitTransition={exitTransition} entryTransition={entryTransition}/>
            )}
        />
    );
}

export default ContentSlider
