import React, {useEffect, useRef} from "react"
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import {gsap, Power2, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TransitionLink from "gatsby-plugin-transition-link";

gsap.registerPlugin(ScrollTrigger);

const Collection = (collection) => {

    let collectionItem = useRef(null);
    let collectionTitle = useRef(null);
    let collectionText = useRef(null);
    let collectionLink = useRef(null);
    let collectionImage = useRef(null);
    let imageFromLeft = collection.collection.Name;

    useEffect(() => {

        let fromSide
        let scaleSize
        let opacityParameter

        if (screen.width < 768) {
            fromSide = 0
            scaleSize = .9
            opacityParameter = 1
        }
        else if (imageFromLeft === "Rockaholik collection") {
            fromSide = -100
            scaleSize = 1
            opacityParameter = 0
        }
        else {
            fromSide = 100
        }

        const collectionST = {
            trigger: collectionItem,
            start: "20% bottom", // when the top of the trigger hits the top of the viewport
            end: "90% bottom",
            toggleActions: "restart none reverse complete",
            scrub: .8, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
        };

        TweenLite.from( collectionImage, {
            scrollTrigger: collectionST,
            opacity: opacityParameter,
            scale: scaleSize,
            x: fromSide,
        });

    },[collectionItem, collectionTitle, collectionText, collectionLink, collectionImage])


    const TRANSITION_LENGTH = .5

    const exitTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPage(),
    }

    const exitReverseTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageReverse(),
    }

    const entryTransition = {
        delay: TRANSITION_LENGTH,
        trigger: () => animateEntryPage(),
    }

    function animateExitPage() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateExitPageReverse() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '-60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateEntryPage() {

        let html = document.querySelector("html");

        setTimeout(() => {
            html.classList.remove("smoothScrollOff")
        }, 120);
    }

    return (
        <div ref={el => collectionItem = el} className="narrow-section main-section">
            <div className="main-section-details">
                <h2 ref={el => (collectionTitle = el)}>{collection.collection.Name}</h2>
                <p ref={el => (collectionText = el)}>{collection.collection.Description}</p>
                <div ref={el => (collectionLink = el)}>
                    <TransitionLink
                        className="btn-cta"
                        to={collection.collection.url}
                        exit={exitTransition}
                        entry={entryTransition}
                    >
                        <span className="collection-number">{collection.collection.Link}</span>
                    </TransitionLink>
                </div>
            </div>
            <div ref={el => (collectionImage = el)} id={collection.collection.Name} className="main-section-img-wrapper">
                <Img className="main-img"
                     fluid={collection.collection.Image.childImageSharp.fluid}
                     alt={collection.collection.Name}
                />
            </div>
        </div>
    );
}

export default Collection
