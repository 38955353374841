import React from "react"

import Index from "../components/layout"
import Homepage from "../components/homepage"

const title = "Gateway Minerals For Nature Addicts";
const description = "A glimpse into the world of minerals where you can enjoy them by swapping, purchasing, spectating the galleries or reading the blog about varying related areas";

const IndexPage = () => (
    <Index
        title={title}
        description={description}
    >
        <Homepage/>
    </Index>
)

export default IndexPage
