import React, {useEffect, useRef} from "react"
import {graphql, Link, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import {gsap, TweenLite, Power3} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {

    let heroImage = useRef(null);
    let heroTitle = useRef(null);
    let heroSubtitle = useRef(null);
    let heroCTA = useRef(null);
    let heroScrollDown = useRef(null);

    useEffect(() => {
        TweenLite.from(
            heroImage,
            1,
            {
                scale: .5,
                opacity: 0,
                ease: Power3.easeOut
            }
        );

        TweenLite.to( heroImage, {
            scrollTrigger: {
                trigger: ".main",
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "bottom bottom",
                toggleActions: "restart pause revers pause",
                scrub: .5 // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            },
            y: -200,
            duration:1.5,
        });

        TweenLite.from(
            heroTitle,
            .8,
            {
                opacity: 0,
                y: 20,
                ease: Power3.easeOut,
                delay: .2,
            }
        );

        TweenLite.from(
            heroSubtitle,
            .8,
            {
                opacity: 0,
                y: 20,
                ease: Power3.easeOut,
                delay: .4,
            }
        );

        TweenLite.from(
            heroCTA,
            .8,
            {
                opacity: 0,
                y: 20,
                ease: Power3.easeOut,
                delay: .5,
            }
        );

        TweenLite.from(
            heroScrollDown,
            1,
            {
                opacity: 0,
                y: 20,
                ease: Power3.easeOut,
                delay: .7,
            }
        );

    },[heroImage, heroTitle, heroSubtitle, heroCTA, heroScrollDown])


    return (
        <StaticQuery
            query={graphql`
        query {
          content: strapiHomePage {
            Hero_section {
              Title
              Subtitle
              Cta_button_text
              Image {
                childImageSharp {
                  fluid {
                     ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      `}
        render={data => (
            <section className="regular-section header-section">
                <div className="header-details">
                    <h1 ref={el => (heroTitle = el)} >{data.content.Hero_section.Title}</h1>
                    <p ref={el => (heroSubtitle = el)} >{data.content.Hero_section.Subtitle}</p>
                    <div ref={el => (heroCTA = el)}>
                        <Link
                            className="btn-cta large-cta"
                            to="#collections"
                        >
                            {data.content.Hero_section.Cta_button_text}
                        </Link>
                    </div>
                    <div ref={el => (heroScrollDown = el)}>
                        <Link
                            className="scroll-down"
                            to="#about"
                        >
                            <span>Scroll down</span>
                            <i className="icons icon-south arrow-bounce bounce"/>
                        </Link>

                    </div>
                </div>
                <div ref={el => (heroImage = el)} className="header-img">
                    <Img className="main-img"
                         fluid={data.content.Hero_section.Image.childImageSharp.fluid}
                         alt={data.content.Hero_section.Title}
                    />
                </div>
            </section>
        )}
      />
    );
}

export default HeroSection

