import React from "react"
import {graphql, StaticQuery} from "gatsby";
import Collection from "./collection";


const Collections = () => {

    return (
        <StaticQuery
            query={graphql`
        query {
          content: strapiHomePage {
            collections {
              Name
              Description
              Link
              url
              Image {
                childImageSharp {
                  fluid {
                     ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      `}
            render={data => (
                <section id="collections" className="collections anchor">
                    {data.content.collections.map((collection, i) => {
                        return (
                            <Collection collection={collection} key={i}/>
                        )
                    })}
                </section>
            )}
        />
    );
}

export default Collections
