import React, {useEffect, useRef, useState} from "react";
import SliderItem from "./sliderItem";
import {gsap, Power3, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Slides = ({ articles, exitTransition, entryTransition }) => {

    let sliderSection = useRef(null);
    let sliderTitle  = useRef(null);
    let sliderNavigation  = useRef(null);

    useEffect(() => {

        const slidesST = {
            trigger: sliderSection,
            start: "top bottom", // when the top of the trigger hits the top of the viewport
            end: "bottom center",
            toggleActions: "restart none revers pause",
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
        };

        TweenLite.from( sliderSection, 1, {
            scrollTrigger: slidesST,
            opacity: 0,
            ease: Power3.easeOut,
        });

        TweenLite.from( sliderTitle, 1, {
            opacity: 0,
            ease: Power3.easeOut,
        });

        TweenLite.from( sliderNavigation, 1, {
            opacity: 0,
            ease: Power3.easeOut,
        });

    },[sliderTitle, sliderNavigation])

    const [index, setIndex] = useState(0); // create state to keep track of images index, set the default index to 0

    const slideRight = () => {

        let slide = document.getElementById('slide');

        TweenLite.to( slide, .2, {
            opacity: 0,
            ease: Power3.easeOut,
        });

        setTimeout(() => {
            setIndex((index + 1) % articles.length); // increases index by 1
        }, 300);

    };

    const slideLeft = () => {

        let slide = document.getElementById('slide');

        TweenLite.to( slide, .2, {
            opacity: 0,
            ease: Power3.easeOut,
        });

        const nextIndex = index - 1;

        setTimeout(() => {
            if (nextIndex < 0) {
                setIndex(articles.length - 1); // returns last index of images array if index is less than 0
            } else {
                setIndex(nextIndex);
            }
        }, 300);
    };

    let number = index

    if (index < 9) {
        number = "0" + (index + 1);
    }

    return (

        <section ref={el => (sliderSection = el)} className="regular-section articles-section content-slider">
            <div className="article-number">
                <span>{number}</span>
            </div>
            <span ref={el => (sliderTitle = el)} className="article-info">Articles</span>
            <SliderItem
                key={articles[index]}
                article={articles[index]}
                exitTransition={exitTransition}
                entryTransition={entryTransition}
            />
            <div ref={el => (sliderNavigation = el)} className="paginator">
                <button onClick={slideLeft}>
                    <i className="icons icon-west"/>
                </button>
                <button onClick={slideRight}>
                    <i className="icons icon-east"/>
                </button>
            </div>
        </section>

    )
}

export default Slides
