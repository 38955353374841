import React, {useEffect, useRef} from "react"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import TransitionLink from "gatsby-plugin-transition-link"
import ReactMarkdown from "react-markdown"
import {gsap, Power3, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const AboutSection = ({ exitTransition, entryTransition }) => {

    let aboutSection = useRef(null);
    let highlightedSection = useRef(null);
    let aboutImage = useRef(null);
    let aboutTitle = useRef(null);
    let aboutSubtitle = useRef(null);
    let aboutCTA = useRef(null);

    useEffect(() => {

        const aboutST = {
            trigger: highlightedSection,
            start: "top bottom", // when the top of the trigger hits the top of the viewport
            end: "bottom center",
            toggleActions: "restart none none reverse",
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        };

        TweenLite.from( aboutImage, .8,{
            scrollTrigger: aboutST,
            // opacity: 0,
            scale: .9,
            ease: Power3.easeOut,
        })

        TweenLite.from( aboutCTA, 1.8, {
            scrollTrigger: aboutST,
            opacity: 0,
            ease: Power3.easeOut,
        })

    },[highlightedSection, aboutImage, aboutTitle, aboutSubtitle, aboutCTA ])

    return (
        <StaticQuery
            query={graphql`
        query {
          content: strapiHomePage {
            About {
              Title
              Content
              Cta_text
              Image {
                childImageSharp {
                  fluid {
                     ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      `}
            render={data => (
                <section ref={el => (aboutSection = el)} id="about" className="highlighted-section-wrapper anchor">
                    <div className="black">
                    </div>
                    <div ref={el => (highlightedSection = el)} className="highlighted-section">
                        <div ref={el => (aboutImage = el)} className="highlighted-img">
                            <Img className="main-img"
                                 fluid={data.content.About.Image.childImageSharp.fluid}
                                 alt={data.content.About.Title}
                            />
                        </div>
                        <div className="highlighted-content">
                            <h2 ref={el => (aboutTitle = el)} >{data.content.About.Title}</h2>
                            <div ref={el => (aboutSubtitle = el)}  className="markdown-container">
                                <ReactMarkdown source={data.content.About.Content} />
                            </div>
                            <div ref={el => (aboutCTA = el)} >
                                <TransitionLink
                                    className="read-more"
                                    to="/about"
                                    exit={exitTransition}
                                    entry={entryTransition}
                                >
                                    <span>{data.content.About.Cta_text}</span>
                                    <i className="icons icon-east arrow-slide slide"/>
                                </TransitionLink>
                            </div>
                        </div>
                    </div>
                    <div className="orange">
                    </div>
                </section>
            )}
        />
    );
}

export default AboutSection
