import React, {useEffect, useRef} from "react"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown";
import excerptAst from "mdast-excerpt"
import {gsap, Power2, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TransitionLink from "gatsby-plugin-transition-link";

gsap.registerPlugin(ScrollTrigger);

const SliderItem = ({ article, exitTransition, entryTransition }) => {

    const MarkdownExcerpt = ({ source, pruneLength}) => (
        <ReactMarkdown
            source={source}
            disallowedTypes={['heading' , 'image']}
            astPlugins={[ast => excerptAst(ast, { pruneLength, truncate: true })]}
        />
    )

    let sliderItem = useRef(null);

    useEffect(() => {

        TweenLite.to( sliderItem, .3, {
            opacity: 1,
            ease: Power2.easeInOut,
            delay: .12,
        });

    },[])

    return (
        <div ref={el => (sliderItem = el)} id={"slide"} className="articles-dynamic-content">

            <div className="articles-img-block">
                <div className={"slImage-wrapper"}>
                    <TransitionLink
                        to={`/blog/${article.node.blog_category.slug}/${article.node.slug}`}
                        exit={exitTransition}
                        entry={entryTransition}
                    >
                        <Img className="main-img"
                             fluid={article.node.image.childImageSharp.fluid}
                             alt={article.node.title}
                        />
                    </TransitionLink>
                </div>
                <div className="articles-img-details">
                    <span className="article-img-category">
                        <img src={article.node.blog_category.icon.publicURL} alt="{article.node.blog_category.name}"/>
                        <span>{article.node.blog_category.name}</span>
                    </span>
                    <span className="article-date">{article.node.published_at}</span>
                </div>
            </div>

            <div className="articles-content">
                <TransitionLink
                    to={`/blog/${article.node.blog_category.slug}/${article.node.slug}`}
                    exit={exitTransition}
                    entry={entryTransition}
                >
                    <h2>{article.node.title}</h2>
                </TransitionLink>
                <div>
                    <MarkdownExcerpt source={article.node.content} pruneLength={178} />
                </div>
                <div>
                    <TransitionLink
                        className="read-more"
                        to={`/blog/${article.node.blog_category.slug}/${article.node.slug}`}
                        exit={exitTransition}
                        entry={entryTransition}
                    >
                        <span>Read more</span>
                        <i className="icons icon-east arrow-slide slide"/>
                    </TransitionLink>
                </div>
            </div>
        </div>
    )
};

export default SliderItem
